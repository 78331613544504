<script setup>
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import RatingSquareScore from '@/Pages/DomainScore/Partials/RatingSquareScore.vue';
import {
  getTlsDnsRecordStatus,
  getMtaDnsRecordStatus
} from '@/Common/DnsRecordMessaging.js';
import Pill from '@/Components/Pill.vue';

const props = defineProps({ score: Object });

const tlsRecordStatus = getTlsDnsRecordStatus(props.score);
const mtaRecordStatus = getMtaDnsRecordStatus(props.score);
</script>

<template>
  <div class="border-t border-t-white/10 pt-4">
    <div class="text-white-smoke-500 mb-2 text-lg">Privacy Rating</div>
    <div class="flex justify-between">
      <div class="text-white-smoke-600 flex flex-col items-start gap-2 pt-2">
        <div class="flex items-center">
          <div class="w-24">
            <Pill :variant="tlsRecordStatus.actionType.color">TLS-RPT</Pill>
          </div>
          {{ tlsRecordStatus.message }}
        </div>

        <div class="flex items-center">
          <div class="w-24">
            <Pill :variant="mtaRecordStatus.actionType.color">MTA-STS</Pill>
          </div>
          {{ mtaRecordStatus.message }}
        </div>
      </div>
      <div class="flex flex-col items-center gap-1">
        <RatingSquareScore :score="score.privacy.score" size="sm" />
        <RiskLabel
          :score="score.privacy.score"
          class="mt-1 text-xs text-nowrap"
        />
      </div>
    </div>
  </div>
</template>
