<script setup>
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import RatingSquareScore from '@/Pages/DomainScore/Partials/RatingSquareScore.vue';
import {
  getDkimDnsRecordStats,
  getSpfDnsRecordStatus,
  getDmarcDnsRecordStatus
} from '@/Common/DnsRecordMessaging.js';
import Pill from '@/Components/Pill.vue';

const props = defineProps({ score: Object });

const dmarcRecordStatus = getDmarcDnsRecordStatus(props.score);
const spfRecordStatus = getSpfDnsRecordStatus(props.score);
const dkimRecordStatus = getDkimDnsRecordStats(props.score);
</script>

<template>
  <div class="border-t border-t-white/10 pt-4">
    <div class="text-white-smoke-500 mb-2 text-lg">Impersonation Rating</div>
    <div class="flex items-start justify-between">
      <div class="text-white-smoke-600 flex flex-col items-start gap-2 pt-2">
        <div class="flex items-center">
          <div class="w-24">
            <Pill :variant="dmarcRecordStatus.actionType.color">DMARC</Pill>
          </div>
          {{ dmarcRecordStatus.message }}
        </div>

        <div class="flex items-center">
          <div class="w-24">
            <Pill :variant="spfRecordStatus.actionType.color">SPF</Pill>
          </div>
          {{ spfRecordStatus.message }}
        </div>

        <div class="flex items-center">
          <div class="w-24">
            <Pill :variant="dkimRecordStatus.actionType.color">DKIM</Pill>
          </div>
          {{ dkimRecordStatus.message }}
        </div>
      </div>
      <div class="flex flex-col items-center gap-1">
        <RatingSquareScore :score="score.impersonation.score" size="sm" />
        <RiskLabel
          :score="score.impersonation.score"
          class="mt-1 text-xs text-nowrap"
        />
      </div>
    </div>
  </div>
</template>
