import { datadogRum } from '@datadog/browser-rum';

export const dataDogRumSetup = () => {
  const {
    VITE_APP_URL,
    VITE_DATADOG_APPLICATION_ID,
    VITE_DATADOG_CLIENT_TOKEN,
    VITE_DATADOG_SAMPLE_RATE
  } = import.meta.env;

  if (VITE_DATADOG_APPLICATION_ID) {
    datadogRum.init({
      applicationId: VITE_DATADOG_APPLICATION_ID,
      clientToken: VITE_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'professor',
      env: window?.globalConfig?.environment ?? 'prod',
      sessionSampleRate: Number(VITE_DATADOG_SAMPLE_RATE),
      sessionReplaySampleRate: Number(VITE_DATADOG_SAMPLE_RATE),
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [VITE_APP_URL],
      enableExperimentalFeatures: ['clickmap']
    });
  }
};
