<script setup>
const props = defineProps({
  score: Number,
  variant: {
    type: String,
    default: 'default',
    validator: (value) =>
      ['success', 'warning', 'danger', 'default'].includes(value)
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value)
  }
});

const classes = {
  success: 'bg-parakeet-500',
  warning: 'bg-dirty-orange-500',
  danger: 'bg-rio-red-500',
  default: 'bg-cool-grey-300'
}[props.variant];

const sizes = {
  md: 'p-4 text-2xl rounded-[20px]',
  sm: 'p-3 text-lg rounded-xl'
}[props.size];
</script>

<template>
  <div class="text-white-smoke-500 font-bold" :class="[classes, sizes]">
    <div class="flex items-baseline px-1 whitespace-nowrap">
      {{ score }}
      <span class="text-sm">/5</span>
    </div>
  </div>
</template>
