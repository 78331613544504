<script setup>
import { getActionTypeFromRating } from '@/Common/Enum/ActionType.js';
import SquareScore from '@/Components/Chart/SquareScore.vue';
import getRating from '@/Common/getRating.js';

const props = defineProps({
  score: Number,
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md'].includes(value)
  }
});

const rating = getRating(props.score);
</script>

<template>
  <SquareScore
    :score="rating"
    :size="size"
    :variant="getActionTypeFromRating(getRating(props.score)).color"
  />
</template>
