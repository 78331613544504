<script setup>
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import RatingSquareScore from '@/Pages/DomainScore/Partials/RatingSquareScore.vue';
import { getBimiDnsRecordStatus } from '@/Common/DnsRecordMessaging.js';
import Pill from '@/Components/Pill.vue';

const props = defineProps({ score: Object });

const bimiRecordStatus = getBimiDnsRecordStatus(props.score);
</script>

<template>
  <div class="border-t border-t-white/10 pt-4">
    <div class="text-white-smoke-500 mb-2 text-lg">Branding Rating</div>
    <div class="flex justify-between">
      <div class="text-white-smoke-600 flex flex-col items-start gap-2 pt-2">
        <div class="flex items-center">
          <div class="w-24">
            <Pill :variant="bimiRecordStatus.actionType.color">BIMI</Pill>
          </div>
          {{ bimiRecordStatus.message }}
        </div>
      </div>

      <div class="flex flex-col items-center">
        <RatingSquareScore :score="score.marketing.score" size="sm" />
        <RiskLabel
          :score="score.marketing.score"
          class="mt-1 text-xs text-nowrap"
        />
      </div>
    </div>
  </div>
</template>
