<script setup>
import { reactive, ref, watch } from 'vue';
import IntlTelInput from 'intl-tel-input/vueWithUtils';
import 'intl-tel-input/styles';
import TextInput from '@/Components/Input/TextInput.vue';
import InputLabel from '@/Components/Input/InputLabel.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import Modal from '@/Components/Modal/Modal.vue';
import IconCheckCircle from '@/Components/Icons/IconCheckCircle.vue';

const props = defineProps({
  domain: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['close']);

const form = reactive({
  firstName: '',
  lastName: '',
  contactNumber: '',
  email: '',
  errors: {},
  processing: false
});

const state = reactive({ submitted: false });
const close = () => {
  emit('close');
};

const isValidNumber = ref(false);
const numberErrorCode = ref(0);
const telephoneInput = ref();

watch(
  () => form.contactNumber,
  () => {
    telephoneInput.value.instance.telInput.setCustomValidity('');
    telephoneInput.value.instance.telInput.reportValidity();
  }
);

const submitForm = () => {
  if (!isValidNumber.value) {
    telephoneInput.value.instance.telInput.setCustomValidity(
      'Please enter a valid telephone number.'
    );
    telephoneInput.value.instance.telInput.reportValidity();
    return;
  }
  axios
    .post(route('score.email.report', props.domain), form)
    .then(() => {
      state.submitted = true;
    })
    .finally(() => (form.processing = false));
};

const download = () => {
  window.open(route('score.export', [props.domain]), '_blank');
  close();
};
</script>

<template>
  <Modal :show="true" @close="close" max-width="lg">
    <template #title>Email Detailed Report</template>
    <div v-if="!state.submitted">
      <p class="pb-8">
        Please complete the form below to receive your detailed domain report
        directly in your inbox. If you're at risk one of our experts will be in
        touch to assist.
      </p>

      <!-- Form -->
      <form @submit.prevent="submitForm" class="flex flex-col gap-4">
        <TextInput
          v-model.trim="form.firstName"
          label="First Name"
          placeholder="Enter First Name"
          required
          error="First name is required"
        />
        <TextInput
          v-model.trim="form.lastName"
          label="Last Name"
          placeholder="Enter Last Name"
          required
        />
        <div class="w-full">
          <input-label value="Contact Number" />
          <IntlTelInput
            ref="telephoneInput"
            class="border-white-smoke-600 bg-navy-100 hover:border-navy-200 flex !w-full rounded-lg px-5 py-2 leading-5 placeholder-gray-400 focus:bg-white focus:ring-0"
            @change-number="form.contactNumber = $event"
            @change-validity="isValidNumber = $event"
            @change-error-code="numberErrorCode = $event"
            :options="{
              initialCountry: 'za'
            }"
            required
          />
        </div>
        <TextInput
          type="email"
          v-model.trim="form.email"
          label="Email Address"
          placeholder="Enter Email Address"
          required
        />

        <!-- Buttons -->
        <div class="flex">
          <primary-button type="submit" :disabled="form.processing">
            Send Report
          </primary-button>
        </div>
      </form>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between rounded-lg bg-green-50 p-5">
        <IconCheckCircle :size="50" class="text-parakeet-600 mr-2 shrink-0" />
        <div class="text-parakeet-700">
          <h2 class="font-semibold">Report Sent</h2>
          The report has been sent. If you're at risk, one of our experts will
          get in touch to assist.
        </div>
      </div>
      <PrimaryButton
        @click="download"
        datadog-action-name="Download Report - modal"
      >
        Download Report
      </PrimaryButton>
    </div>
  </Modal>
</template>
