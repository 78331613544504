import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import { API_STATUS } from '@/Common/Enum/ApiStatus.js';
import ActionType from '@/Common/Enum/ActionType.js';

export function getDmarcDnsRecordStatus(result) {
  if (!result.impersonation.has_dmarc_record) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Record not found'
    };
  }

  const hasTooManyRecords = (result.records?.dmarc?.tags || []).some(
    (tag) => tag.msg === 'Too many records'
  );

  if (hasTooManyRecords) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Multiple DMARC records found'
    };
  }

  if (
    [API_STATUS.FAIL, API_STATUS.FATAL].includes(result.records?.dmarc?.status)
  ) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Invalid record found'
    };
  }

  if (result.records?.dmarc?.status === API_STATUS.ERROR) {
    return {
      actionType: ACTION_TYPES.ACTION_RECOMMENDED,
      message: 'Record found with errors'
    };
  }

  if (result.records?.dmarc?.status === API_STATUS.INFO) {
    return {
      actionType: ACTION_TYPES.INFO,
      message: 'Record found with warnings'
    };
  }

  return {
    actionType: ACTION_TYPES.NO_ACTION,
    message: 'Record found without errors'
  };
}

export function getSpfDnsRecordStatus(result) {
  const tags = Object.fromEntries(
    result.records?.spf?.tags?.map((tag) => [tag.msg, tag]) ?? []
  );

  if (tags['no spf no mx'] && !result.impersonation?.has_spf_record) {
    return {
      actionType: ACTION_TYPES.INFO,
      message: 'No MX records. SPF not required.',
      canAnalyse: false
    };
  }

  if (!result.impersonation?.has_spf_record) {
    return {
      actionType: ACTION_TYPES.NO_ACTION,
      message: 'Record not found',
      canAnalyse: false
    };
  }

  if (tags['Too many records']) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Multiple SPF records found',
      canAnalyse: true
    };
  }

  const statusMap = {
    [API_STATUS.FAIL]: {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Invalid record found',
      canAnalyse: true
    },
    [API_STATUS.FATAL]: {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Invalid record found',
      canAnalyse: true
    },
    [API_STATUS.ERROR]: {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Record found with errors',
      canAnalyse: true
    },
    [API_STATUS.INFO]: {
      actionType: ACTION_TYPES.ACTION_RECOMMENDED,
      message: 'Record found with warnings',
      canAnalyse: true
    }
  };

  return (
    statusMap[result.records?.spf?.status] ?? {
      actionType: ACTION_TYPES.NO_ACTION,
      message: 'Record found without errors',
      canAnalyse: true
    }
  );
}

export function getDkimDnsRecordStats(result) {
  const tags = Object.fromEntries(
    result.records?.dkim?.tags?.map((tag) => [tag.msg, tag]) ?? []
  );

  const tagMessages = {
    'weak keys': {
      message: 'Weak DKIM Keys found',
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'invalid keys': {
      message: 'Invalid DKIM Keys found',
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'no keys': {
      message: 'No known DKIM Keys found',
      actionType: ActionType.INFO
    },
    'error keys': {
      message: 'DKIM Keys contain errors',
      actionType: ActionType.ACTION_RECOMMENDED
    },
    'no dkim no mx': {
      message: 'No MX records. DKIM not required.',
      actionType: ActionType.INFO
    },
    'large keys': {
      message: 'Large DKIM Keys found',
      actionType: ActionType.INFO
    },
    'no errors': {
      message: 'DKIM Keys found without errors',
      actionType: ActionType.NO_ACTION
    },
    'dkim wildcard': {
      message: 'DKIM Lookup contains wildcard response',
      actionType: ActionType.ACTION_RECOMMENDED
    }
  };

  let actionType = null;
  let messages = [];

  Object.keys(tags).forEach((tag) => {
    if (tagMessages.hasOwnProperty(tag)) {
      if (actionType === null) {
        actionType = tagMessages[tag].actionType;
      }
      messages.push(tagMessages[tag].message);
    }
  });

  if (
    tags.hasOwnProperty('no dkim no mx') &&
    !result.impersonation.num_dkim_records
  ) {
    return {
      actionType,
      message: 'No MX records. DKIM not required.',
      canAnalyse: false
    };
  }

  if (!result.impersonation.num_dkim_records) {
    return {
      actionType: ACTION_TYPES.INFO,
      message: 'No known DKIM Keys found',
      canAnalyse: false
    };
  }

  if (!actionType) {
    return {
      actionType: ActionType.NO_ACTION,
      message: `DKIM Keys found without errors`,
      canAnalyse: true
    };
  }

  return { actionType, message: messages.join('. '), canAnalyse: true };
}

export function getBimiDnsRecordStatus(result) {
  if (!result.marketing.has_bimi_record) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'No record found',
      canAnalyse: false
    };
  }

  if (result.records.bimi.tags.some((tag) => tag.msg === 'Invalid record')) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Invalid record found',
      canAnalyse: true
    };
  }

  if (result.records.bimi.status === API_STATUS.ERROR) {
    return {
      actionType: ACTION_TYPES.ACTION_RECOMMENDED,
      message: 'Record contains errors',
      canAnalyse: true
    };
  }

  return {
    actionType: ACTION_TYPES.NO_ACTION,
    message: 'Record found without errors',
    canAnalyse: true
  };
}

export function getTlsDnsRecordStatus(result) {
  if (!result.privacy.has_tlsrpt_record) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'No record found',
      canAnalyse: false
    };
  }

  const tags = Object.fromEntries(
    result.records?.tlsrpt?.tags?.map((tag) => [tag.msg, tag]) ?? []
  );

  if (tags['Too many records']) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Multiple records found',
      canAnalyse: true
    };
  }

  if (
    [API_STATUS.FAIL, API_STATUS.FATAL].includes(result.records?.tlsrpt?.status)
  ) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Invalid record found',
      canAnalyse: true
    };
  }

  if (tags['errors']) {
    return {
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      message: 'Record contains errors',
      canAnalyse: true
    };
  }

  return {
    actionType: ACTION_TYPES.NO_ACTION,
    message: 'Record found without errors',
    canAnalyse: true
  };
}

export function getMtaDnsRecordStatus(result) {
  if (!result.privacy.has_mtasts_record) {
    return {
      message: 'No record found',
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      canAnalyse: false
    };
  }

  const tags = Object.fromEntries(
    result.records?.mtasts?.tags?.map((tag) => [tag.msg, tag]) ?? []
  );

  if (tags.hasOwnProperty('Too many records')) {
    return {
      message: 'Multiple records found',
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      canAnalyse: true
    };
  }

  if (
    [API_STATUS.FAIL, API_STATUS.FATAL].includes(result.records?.tlsrpt?.status)
  ) {
    return {
      message: 'Invalid record found',
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      canAnalyse: true
    };
  }

  if (tags.hasOwnProperty('errors')) {
    return {
      message: 'Record contain errors',
      actionType: ACTION_TYPES.ACTION_REQUIRED,
      canAnalyse: true
    };
  }

  return {
    message: 'Record found without errors',
    actionType: ACTION_TYPES.NO_ACTION,
    canAnalyse: true
  };
}
