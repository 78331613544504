<script setup>
const props = defineProps({
  score: {
    type: Number,
    validator: (value) => value <= 100
  }
});

const radiusOuter = 11;
const circumferenceOuter = 2 * Math.PI * radiusOuter;
const strokeDasharrayOuter = circumferenceOuter;
const strokeDashoffsetOuter =
  circumferenceOuter - (props.score / 100) * circumferenceOuter;

const radiusInner = 7;
const circumferenceInner = 2 * Math.PI * radiusInner;
const strokeDasharrayInner = circumferenceInner;
const strokeDashoffsetInner =
  circumferenceInner - (props.score / 100) * circumferenceInner;

const variants = {
  red: {
    bg: 'text-rio-red-200 dark:text-[#B713224D]',
    full: 'text-rio-red-500 dark:text-rio-red-500',
    inner: 'text-rio-red-400 dark:text-rio-red-700',
    outer: 'text-rio-red-300 dark:text-rio-red-800'
  },
  orange: {
    bg: 'text-dirty-orange-200 dark:text-dirty-orange-900',
    full: 'text-dirty-orange-500 dark:text-dirty-orange-500',
    inner: 'text-dirty-orange-400 dark:text-dirty-orange-700',
    outer: 'text-dirty-orange-300 dark:text-dirty-orange-800'
  },
  cyan: {
    bg: 'text-blue-200 dark:text-blue-900',
    full: 'text-blue-500 dark:text-blue-600',
    inner: 'text-blue-400 dark:text-blue-500',
    outer: 'text-blue-300 dark:text-blue-400'
  },
  green: {
    bg: 'text-parakeet-200 dark:text-parakeet-900',
    full: 'text-parakeet-500 dark:text-parakeet-500',
    inner: 'text-parakeet-400 dark:text-parakeet-700',
    outer: 'text-parakeet-300 dark:text-parakeet-800'
  }
};

const colourSet = (() => {
  if (props.score < 50) {
    return variants.red;
  } else if (props.score < 80) {
    return variants.orange;
  } else {
    return variants.green;
  }
})();
</script>

<template>
  <div class="relative">
    <svg class="h-full w-full rotate-90 transform" viewBox="0 0 30 30">
      <circle
        :class="`stroke-current ${colourSet.bg}`"
        fill="transparent"
        r="9"
        cx="15"
        cy="15"
        stroke-width="8"
      />
      <circle
        :class="`stroke-current transition-all duration-1000 ease-in-out ${colourSet.outer}`"
        fill="transparent"
        r="11"
        cx="15"
        cy="15"
        stroke-width="4"
        :stroke-dasharray="strokeDasharrayOuter"
        :stroke-dashoffset="strokeDashoffsetOuter"
      />
      <circle
        :class="`stroke-current transition-all duration-1000 ease-in-out ${colourSet.inner}`"
        fill="transparent"
        r="7"
        cx="15"
        cy="15"
        stroke-width="4"
        :stroke-dasharray="strokeDasharrayInner"
        :stroke-dashoffset="strokeDashoffsetInner"
      />
      <circle :class="`fill-current ${colourSet.full}`" r="5" cx="15" cy="15" />
    </svg>
    <div
      class="absolute inset-0 flex items-center justify-center text-lg font-bold text-white md:text-2xl"
    >
      {{ score }}
    </div>
  </div>
</template>

<style scoped>
svg {
  transform: rotate(-90deg);
}
</style>
