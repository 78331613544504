<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';
import { getTlsDnsRecordStatus } from '@/Common/DnsRecordMessaging.js';

const result = inject('score');

const tlsRecordStatus = getTlsDnsRecordStatus(result);
</script>

<template>
  <Card class="bg-white-opacity">
    <DetailedReportHeader
      title="TLS-RPT or SMTP TLS Reporting"
      description="Transport Layer Security Reporting"
      tooltip="Enables sending systems to share statistics and information about failures with recipient domains."
    />
    <div class="mt-5 flex flex-col">
      <ExplainScorePart
        title="DNS Record"
        :action-type="tlsRecordStatus.actionType"
      >
        {{ tlsRecordStatus.message }}
        <div v-if="tlsRecordStatus.canAnalyse">
          &nbsp;-
          <LinkDomainAnalysis :domain="result.domain" fragment="tls" />
        </div>
      </ExplainScorePart>

      <div>
        <ExplainScorePart
          v-if="
            !result.records.tlsrpt.tlsrpt_providers.includes('Unknown') &&
            result.records.tlsrpt.tlsrpt_providers.length
          "
          title="Providers"
          :action-type="ACTION_TYPES.INFO"
        >
          {{ result.records.tlsrpt.tlsrpt_providers.join(', ') }}
        </ExplainScorePart>
        <ExplainScorePart
          v-else
          title="Providers"
          :action-type="ACTION_TYPES.INFO"
        >
          <div>
            None -
            <a
              href="https://sendmarc.com/contact/"
              class="underline"
              target="_blank"
            >
              Contact us
            </a>
            for assistance
          </div>
        </ExplainScorePart>
      </div>
    </div>
  </Card>
</template>
