<script setup>
import { onMounted, onBeforeUnmount, reactive, ref } from 'vue';
import InputLabel from '@/Components/Input/InputLabel.vue';
import InputDescription from '@/Components/Input/InputDescription.vue';
import IconClose from '@/Components/Icons/IconClose.vue';
import getInputSizeClasses from '@/Components/Input/sizing.js';
import { newInputId } from '@/Common/utils.js';

const props = defineProps({
  id: {
    type: String,
    default: () => newInputId()
  },
  modelValue: String,
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  error: {
    type: [String],
    default: null
  },
  label: {
    type: [String, Boolean],
    default: false
  },
  labelSize: {
    type: String,
    default: 'md'
  },
  labelClasses: {
    type: String,
    required: false
  },
  description: {
    type: [String, Boolean],
    default: false
  },
  size: {
    type: String,
    default: 'medium'
  },
  variant: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'warning', 'danger'].includes(value)
  },
  required: Boolean,
  clearable: Boolean
});

const state = reactive({
  value: props.modelValue
});

const borderVariantClasses = {
  warning: 'border-dirty-orange-600 focus:border-dirty-orange-600',
  danger: 'border-red-600 focus:border-red-600',
  default: 'border-white-smoke-600 hover:border-navy-200'
};

const sizeClasses = getInputSizeClasses(props.size);

const emits = defineEmits(['update:modelValue']);

const input = ref(null);

const clear = () => {
  emits('update:modelValue', '');
  state.value = '';
  input.value.focus();
};

const handleKeyDown = (event) => {
  if (event.key === 'Escape' && state.value) {
    clear();
  }
};

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
  input.value.addEventListener('keydown', handleKeyDown);
});

onBeforeUnmount(() => {
  input.value.removeEventListener('keydown', handleKeyDown);
});

defineExpose({ focus: () => input.value.focus() });

defineOptions({
  inheritAttrs: false
});
</script>

<template>
  <div class="flex w-full flex-1 flex-col">
    <InputLabel
      v-if="label"
      :for="id"
      :size="labelSize"
      :value="label"
      :class="labelClasses"
    />
    <div :class="{ relative: clearable }">
      <input
        :id="id"
        v-bind="$attrs"
        ref="input"
        v-model="state.value"
        :class="[borderVariantClasses[variant], sizeClasses]"
        :required="required"
        class="bg-navy-100 w-full rounded-lg focus:bg-white focus:ring-1"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <button
        v-if="clearable && state.value?.length > 0"
        type="button"
        class="absolute top-1/2 right-2 -translate-y-1/2 transform cursor-pointer rounded-full p-2 text-gray-700"
        tabindex="-1"
        @click="clear"
      >
        <IconClose class="h-5 w-5" />
      </button>
    </div>
    <InputDescription v-if="description !== false" :variant="variant">
      {{ description }}
    </InputDescription>
  </div>
</template>
