<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import ActionType from '@/Common/Enum/ActionType.js';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';
import { getBimiDnsRecordStatus } from '@/Common/DnsRecordMessaging.js';

const result = inject('score');

const imageMessage = (() => {
  if (result.records.bimi.has_valid_logo) {
    return {
      message: 'Valid BIMI Image',
      actionType: ActionType.NO_ACTION
    };
  }

  if (result.records.bimi.has_logo) {
    return {
      message: 'Image found with errors',
      actionType: ActionType.ACTION_RECOMMENDED
    };
  }

  return {
    message: 'Image not found',
    actionType: ActionType.ACTION_REQUIRED
  };
})();

const certificateMessage = (() => {
  if (result.records.bimi.has_valid_certificate) {
    return {
      message: 'Valid certificate',
      actionType: ActionType.NO_ACTION
    };
  }

  if (result.records.bimi.has_certificate) {
    return {
      message: 'Certificate contains errors',
      actionType: ActionType.ACTION_RECOMMENDED
    };
  }

  return {
    message: 'No valid certificate found',
    actionType: ActionType.ACTION_REQUIRED
  };
})();

const bimiRecordStatus = getBimiDnsRecordStatus(result);
</script>

<template>
  <Card class="bg-white-opacity mt-4">
    <DetailedReportHeader
      title="BIMI"
      description="Brand Indicators for Message Identification"
      tooltip="The BIMI record works alongside DMARC, SPF, and DKIM to verify an email's authenticity and boost brand visibility."
    />
    <div class="mt-5">
      <ExplainScorePart
        title="DNS Record"
        :action-type="bimiRecordStatus.actionType"
      >
        {{ bimiRecordStatus.message }}
        <div v-if="bimiRecordStatus.canAnalyse">
          &nbsp;-
          <LinkDomainAnalysis :domain="result.domain" fragment="bimi" />
        </div>
      </ExplainScorePart>
      <div v-if="result.marketing.has_bimi_record">
        <ExplainScorePart title="Image" :action-type="imageMessage.actionType">
          {{ imageMessage.message }}
        </ExplainScorePart>
        <ExplainScorePart
          title="Certificate"
          :action-type="certificateMessage.actionType"
        >
          {{ certificateMessage.message }}
        </ExplainScorePart>
      </div>
    </div>
  </Card>
</template>
