<script setup>
import RiskIcon from '@/Pages/Common/StatusIcon.vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';

const props = defineProps({
  title: String,
  actionType: Object
});

const tooltip = (() => {
  if (props.actionType === ACTION_TYPES.ACTION_RECOMMENDED) {
    return 'Action is Required';
  } else if (props.actionType === ACTION_TYPES.ACTION_REQUIRED) {
    return 'Action is Recommended';
  } else if (props.actionType === ACTION_TYPES.INFO) {
    return 'Information';
  }
  return 'No Action Required';
})();
</script>

<template>
  <div
    class="mb-2 flex flex-col items-start gap-2 text-sm last:mb-0 md:flex-row md:items-center md:gap-0"
  >
    <div class="font-bold md:w-1/6">{{ title }}</div>
    <div class="flex items-center">
      <RiskIcon
        class="mr-2 shrink-0"
        :action-type="actionType"
        v-tooltip="tooltip"
      />
      <div class="flex break-all">
        <slot />
      </div>
    </div>
  </div>
</template>
