<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';
import { getDkimDnsRecordStats } from '@/Common/DnsRecordMessaging.js';

const result = inject('score');
const dkimDnsRecordStatus = getDkimDnsRecordStats(result);
</script>

<template>
  <Card class="bg-white-opacity">
    <DetailedReportHeader
      title="DKIM"
      description="DomainKeys Identified Mail"
      tooltip="The DKIM record stores the public key used to verify any email signed with the corresponding private key, associating the message with the domain."
    />
    <div class="mt-5 flex flex-col">
      <ExplainScorePart
        title="DNS Record"
        :action-type="dkimDnsRecordStatus.actionType"
      >
        {{ dkimDnsRecordStatus.message }}
        <div v-if="dkimDnsRecordStatus.canAnalyse">
          &nbsp;-
          <LinkDomainAnalysis :domain="result.domain" fragment="spf" />
        </div>
      </ExplainScorePart>
      <ExplainScorePart
        v-if="result.impersonation.num_dkim_records"
        title="Selectors"
        :action-type="ACTION_TYPES.INFO"
      >
        {{ result.impersonation.num_dkim_records }} Selectors found
      </ExplainScorePart>
    </div>
  </Card>
</template>
